/**
 * @file
 * Styles for checkout progress
 */

@import "variables";

  // Progress Colors
  $gray-ultralight: #eee;

  // Greens
  $green-dark: #4f8a10;
  $green: lighten($green-dark, 10%);

  $green-ultralight: #dff2bf;
  $green-light: darken($green-ultralight, 10%);

  // Visited Greens
  $green1: darken($green-light, 28%);
  $green2: darken($green-light, 21%);
  $green3: darken($green-light, 14%);
  $green4: darken($green-light, 7%);
  $green5: darken($green-light, 0%);

// Progress Wrapper
.inline.commerce-checkout-progress {
  display: block;
  padding: 0 0 0 8px;
  @include box-sizing(border-box);
  background: #f1f1f1;
  @include box-shadow(inset 0 0 10px rgba(black, 0.1));
  @include border-radius(15px);
  max-width: 1200px;
  margin: 0 auto 20px;
  list-style: none;
  counter-reset: li; // Initiate a counter

  // Checkout Step (1,2,3... etc)
  li {
    float: left;
    width: 14%;
    list-style-position: inside;
    position: relative;
    z-index: 1;
    height: 32px;
    top: 5px;
    margin: 0;
    padding-left: 32px;

    // hide offsite payment and confirm order pages
    &.payment, &.paypal_ec {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      left: 0;
      top: -5px;
      height: 100%;
    }

    &:after {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      left: -10px;
      top: -7px;
      background: $gray-ultralight;
      @include border-radius(50%);
      height: 12px;
      width: 32px;
      text-align: center;
      line-height: 1em;
      padding: 10px 0;
      border: 2px solid white;
      @include border-radius(50%);
      @include box-shadow(0 0 10px rgba(black, 0.1));
    }

    // Active Step
    &.active {
      color: white;
      font-weight: normal;
      padding-left: 41px;
      &:before {
        background: $green;
        @include box-shadow(inset 0 0 10px rgba(black, 0.1));
      }
      &:after {
        background: $green;
        @include box-shadow(0 0 10px rgba(black, 0.1));
        left: -8px;
        top: -10px;
        padding: 12px 0;
        height: 14px;
        width: 38px;
      }

      a {
        color: white;
        text-decoration: underline;
      }

    }

    // Visited Step
    &.visited {

      &:before {
        background: $green-light;
        @include border-radius(0 3px 3px 0);
        border-right: 1px solid white;
        @include box-shadow(inset 0 0 10px rgba(black, 0.1));
      }

      &:after {
        background: $green-light;
        content: "c";
        font-family: fontello;
        @include box-shadow(0 0 10px rgba(black, 0.1));
        //left: -8px;
        //top: -10px;
        //padding: 12px 0;
        //height: 14px;
        //width: 38px;
      }

      &:last-child { // Darkest
        &:before {
          background: $green1;
        }
        &:after {
          background: $green1;
        }
      }

      &:nth-last-child(2) { // Darker
        &:before {
          background: $green2;
        }
        &:after {
          background: $green2;
        }
      }

      &:nth-last-child(3) { // Dark
        &:before {
          background: $green3;
        }
        &:after {
          background: $green3;
        }
      }

      &:nth-last-child(4) { // Light
        &:before {
          background: $green4;
        }
        &:after {
          background: $green4;
        }
      }

      &:nth-last-child(5) { // Lightest
        &:before {
          background: $green5;
        }
        &:after {
          background: $green5;
        }
      }

      a {
        color: #444444;
        text-decoration: underline;
      }
    }

    // Round First and Last Edges
    &.first {
      &:before {
        @include border-radius(20px 0 0 20px);
      }
    }

    &.active.first {
      &:before {
        @include border-radius(20px 4px 4px 20px);
      }
    }

    &.last {
      &:before {
        @include border-radius(0 4px 4px 0);
      }
    }

  }

}

// Paypal EC Form
.paypal-ec-order-form {
  text-align: left;
}

//
// Mobile Checkout Progress
//

@media only screen and (max-width: 1280px) {

  // Progress Wrapper
  .inline.commerce-checkout-progress {
    display: inline-block;
    position: relative;
    //left: 50%;
    //margin-left: -405px;
    // Checkout Step (1,2,3... etc)
    li {
      width: inherit;
      padding: 0 20px 0 40px;
    }
  }

}

//
// XS Mobile
//

@media only screen and (max-width: 1045px) {

  // Progress Wrapper
  .inline.commerce-checkout-progress {
    background: none;
    @include box-shadow(none);
    display: block;
    position: static;
    margin-left: 0;
    // Checkout Step (1,2,3... etc)
    li {
      display: list-item;
      float: none;
      margin: 0 0 15px 0;
      &:before {
        background: $gray-ultralight;
        @include border-radius(4px);
      }

      &:after {
        left: -8px;
        top: -10px;
        padding: 12px 0;
        height: 14px;
        width: 38px;
      }

      // Visited Step
      &.visited, &.active {
        //color: $primary-color;

        &:before {

        }

        &:after {

        }

        a {
          //color: $primary-color;
        }

      }

      // Active Step
      &.active {
        &:before {

        }
      }

      // Round First and Last Edges
      &.first {
        &:before {
          @include border-radius(4px);
        }
      }

      &.active.first {
        &:before {
        }
      }

      &.last {
        &:before {
        }
      }

    }

  }

}
